<template>
  <div class="secondPieceContent">
    <div class="tabbarHeader">
      <Tabbar :tabbars="tabbars" @tabActive="tabActive"></Tabbar>
    </div>
    <div class="tabbarContent">
      <el-carousel
        height="100%"
        :initial-index="tabIndex"
        indicator-position="none"
        arrow="never"
        :autoplay="false"
        :loop="false"
        ref="tabbarContent"
      >
        <el-carousel-item v-for="item in tabbars" :key="item.className">
          <div
            :is="item.componentName"
            :postInfo="postInfo"
            @replyMessageReceived="replyMessageReceived"
            @refreshCommentCount="refreshCommentCount"
          ></div>
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
</template>
<script>
import Tabbar from "../../../../components/tabbar";
import VideoList from "./recommendedVideoList";
import CommentListBox from "./commentListBox";
import LikesListBox from "./likesListBox";
export default {
  components: {
    Tabbar,
    VideoList,
    CommentListBox,
    LikesListBox,
  },
  props: {
    postInfo: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  computed: {
    // 是否是视频侧边栏
    isVideo() {
      return this.$store.state.homePage.multimediaPopupConfig.videoPath != "";
    },
    tabbars() {
      let tabbarsBox = [
        {
          className: "icon-information-fill",
          countText: this.postInfo.commentCount,
          componentName: "CommentListBox",
        },
        {
          className: "icon-fabulous-fill",
          countText: this.postInfo.likeCount,
          componentName: "LikesListBox",
        },
      ];
      if (this.isVideo) {
        tabbarsBox.unshift({
          className: "icon-video-fill",
          componentName: "VideoList",
        });
      }
      return tabbarsBox;
    },
  },
  data() {
    return {
      tabIndex: 0,
    };
  },
  methods: {
    // 获取tabbar点击事件
    tabActive(tabIndex) {
      this.tabIndex = tabIndex;
      this.$refs.tabbarContent.setActiveItem(this.tabIndex);
    },
    // 收到回复消息
    replyMessageReceived(comment) {
      this.$emit("replyMessageReceived", comment);
    },
    // 更新评论数量
    refreshCommentCount(count) {
      this.postInfo.commentCount = count;
    },
  },
};
</script>
<style lang="stylus" scoped>
.secondPieceContent
  width 100%;
  min-height 0;
  flex 1;
  display flex;
  flex-flow column;
  align-items center;
  justify-content flex-start;
  .tabbarHeader
    width 100%;
    height 45px;
    flex-shrink 0;
    position relative;
    box-sizing border-box;
  .tabbarContent
    width 100%;
    min-height 0;
    flex: 1;
    box-sizing border-box;
</style>
<style lang="stylus">
.tabbarContent
  .el-carousel
    height 100%;
</style>
