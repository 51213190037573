<template>
  <div class="videoBox" v-if="path != ''" v-loading="videoLoading">
    <video 
      ref="videoPlayer"
      :src="path"
      webkit-playsinline="true"
      playsinline="true"
      x-webkit-airplay="true"
      x5-video-player-type="h5"
      x5-video-player-fullscreen="true"
      x5-video-orientation="portraint"
      style="object-fit:contain"
      @loadedmetadata="videoLoadedmetadata"
    >your browser does not support the video tag</video>
    <div class="timeTextBlock">{{totalTime}}</div>
  </div>
</template>
<script>
export default {
  props: {
    path:{
      type: String,
      default: ''
    }
  },
  data() {
    return {
      // video总时长
      totalTime: '',
      // 加载状态
      videoLoading: true
    }
  },
  methods: {
    // 视频元数据加载成功，可以开始播放，并可以使用video对象的数据
    videoLoadedmetadata() {
      this.videoLoading = false;
      // 获取过滤后的时间文本
      this.totalTime = this.timeFormat(this.$refs.videoPlayer.duration);
    },
    //时间格式化处理
    timeFormat(time) {
      let minute = Math.floor((time / 1000) / 60)
      let second = Math.floor((time / 1000) % 60)
      minute = minute < 10 ? "0" + minute : minute
      second = second < 10 ? "0" + second : second
      return `${minute}:${second}`
    },
  }
}
</script>
<style lang="stylus" scoped>
  .videoBox
    width 100%;
    height 100%;
    overflow hidden;
    position relative;
    border-radius 8px;
    video 
      width 100%;
      height 100%;
    .timeTextBlock
      position absolute;
      border-radius 2px;
      padding 1px 5px;
      background rgba(0,0,0,0.6);
      color #FFFFFF;
      font-size 10px;
      z-index 2;
      bottom 6px;
      right 6px;
      display flex;
      align-items center;
      justify-content center;

</style>