var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "commentSlidingWindow" }, [
    _c("div", { staticClass: "slidingWindowHeader" }, [
      _c(
        "div",
        {
          staticClass: "returnButton",
          on: {
            click: function($event) {
              $event.stopPropagation()
              return _vm.$emit("closeSlidingWindow")
            }
          }
        },
        [_c("span", { staticClass: "iconfont_Me icon-left iconStyle" })]
      ),
      _c("div", { staticClass: "titleStyle" }, [
        _vm._v("共" + _vm._s(this.comment.childrenCount) + "条回复")
      ])
    ]),
    _c(
      "div",
      { staticClass: "slidingWindowContainer" },
      [
        _c("Comment", {
          attrs: { comment: _vm.comment },
          on: { replyMessageReceived: _vm.replyMessageReceived }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }