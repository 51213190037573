<template>
  <div class="listAreaContent">
    <LikesList :likeProviders="likeProviders" v-show="likeProviders && likeProviders.length !== 0"></LikesList>
    <PlaceholderMap :placeholderType="0" v-show="likeProviders && likeProviders.length === 0"></PlaceholderMap>
  </div>
</template>
<script>
import LikesList from "../../../../components/list/likesList";
import PlaceholderMap from "../../../../components/placeholderMap";
export default {
  components: {
    LikesList,
    PlaceholderMap
  },
  props: {
    postInfo: {
      type: Object,
      default: ()=>{
        return {}
      }
    }
  },
  computed: {
    likeProviders() {
      return this.postInfo.likeProviders
    }
  },
  data() {
    return {
    }
  },
  methods: {
  }
  
}
</script>
<style lang="stylus" scoped>
  .listAreaContent
    width 100%;
    height 100%;
    overflow-x hidden;
    overflow-y auto;
    box-sizing border-box;
    padding-left 1px;
    scrollbar-width: none;
    &::-webkit-scrollbar
      width 0;

</style>