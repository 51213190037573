<template>
  <div class="commentSlidingWindow">
    <div class="slidingWindowHeader">
      <div class="returnButton" @click.stop="$emit('closeSlidingWindow')">
        <span class="iconfont_Me icon-left iconStyle"></span>
      </div>
      <div class="titleStyle">共{{this.comment.childrenCount}}条回复</div>
    </div>
    <div class="slidingWindowContainer">
      <Comment :comment="comment" @replyMessageReceived="replyMessageReceived"></Comment>
    </div>
  </div>
</template>
<script>
import Comment from "./mainCommentForMoreWindow";
export default {
  components: {
    Comment
  },
  props: {
    comment: {
      type: Object,
      default: ()=>{
        return {}
      }
    }
  },
  methods: {
    replyMessageReceived(comment) {
      this.$emit('replyMessageReceived',comment)
    }
  }
}
</script>
<style lang="stylus" scoped>
  .commentSlidingWindow
    top 0;
    left 0;
    z-index 2;
    width 100%;
    height 100%;
    background #FFFFFF;
    position absolute;
    display flex;
    flex-flow column;
    align-items center;
    justify-content flex-start;
    .slidingWindowHeader
      width 100%;
      height 45px;
      flex-shrink 0;
      box-sizing border-box;
      border-bottom 1px solid #E6E6E6;
      position relative;
      text-align center;
      line-height 45px;
      user-select none;
      .returnButton
        width 22px;
        height 22px;
        display flex;
        cursor pointer;
        align-items center;
        justify-content center;
        position absolute;
        left 0;
        top 50%;
        transform translate3d(0,-50%,0);
        .iconStyle
          color #333333;
          font-size 22px;
      .titleStyle
        font-size 14px;
        color #373737
    .slidingWindowContainer
      flex 1;
      width 100%;
      min-height 0;
      padding-top 18px;
      box-sizing border-box;

</style>