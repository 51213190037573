var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "firstPiece" },
    [
      _c("div", { staticClass: "firstRow" }, [
        _c(
          "div",
          { staticClass: "infoForUser" },
          [
            _c("PostHeader", {
              attrs: {
                headerData: _vm.headerData,
                vipStatus: _vm.headerData.vipStatus,
                showViewCount: false
              }
            })
          ],
          1
        ),
        _c("div", { staticClass: "operatingArea" }, [
          _c(
            "div",
            {
              staticClass: "goChatButton commonButton commomButtonHover",
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  return _vm.startChatting.apply(null, arguments)
                }
              }
            },
            [
              _c("span", {
                staticClass: "iconfont_Me icon-information-fill buttonStyle"
              })
            ]
          ),
          _vm.postInfo.providerId !== _vm.$store.getters.userInfo.id
            ? _c(
                "div",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.friendStatusLoading,
                      expression: "friendStatusLoading"
                    }
                  ],
                  staticClass: "operateFriends commonButton",
                  style: {
                    background:
                      _vm.blacklistButtonStatus && !_vm.postInfo.friendStatus
                        ? "#B3B3B3"
                        : "",
                    cursor:
                      _vm.blacklistButtonStatus && !_vm.postInfo.friendStatus
                        ? "not-allowed"
                        : ""
                  },
                  attrs: {
                    "element-loading-spinner": "el-icon-loading",
                    "element-loading-background": "rgba(0, 0, 0, 0.8)"
                  },
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.changeFriendStatus.apply(null, arguments)
                    }
                  }
                },
                [
                  _c("span", {
                    staticClass: "iconfont_Me buttonStyle",
                    class: _vm.postInfo.friendStatus
                      ? "icon-deleteuser-fill"
                      : "icon-Adduser-fill"
                  })
                ]
              )
            : _vm._e()
        ])
      ]),
      _vm.postInfo.detail
        ? _c("TextDisplay", {
            attrs: { postDetail: _vm.postDetail },
            on: { jumpToPage: _vm.jumpToPage }
          })
        : _vm._e(),
      _vm.tags.length !== 0
        ? _c(
            "div",
            { staticClass: "labelRow" },
            _vm._l(_vm.tags, function(item, index) {
              return _c("Label", {
                key: index + item,
                attrs: { labelContent: item }
              })
            }),
            1
          )
        : _vm._e(),
      _c("PostFooter", {
        attrs: { footerInfo: _vm.footerInfo },
        on: {
          changelikeStatus: _vm.changelikeStatus,
          jumpToPage: _vm.jumpToPage
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }