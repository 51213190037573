<template>
  <div class="multifunction">
    <div class="displayAreaOuter">
      <div class="sidebar">
        <div class="buttonUniformStyle leftButton" @click="previousPicture">
          <span class="iconfont_Me icon-left uniformStyle"></span>
        </div>
      </div>
      <div class="mainDisplayArea">
        <DisplayArea :params="config"></DisplayArea>
      </div>
      <div class="sidebar">
        <div class="buttonBox">
          <SharePopover
            :shareConfig="shareConfig"
            @shareForMelinked="shareForMelinked"
            v-show="config.showShare"
          >
            <div class="buttonUniformStyle">
              <span class="iconfont_Me icon-share2 uniformStyle"></span>
            </div>
          </SharePopover>
          <div class="buttonUniformStyle" @click.stop="downloadPicture">
            <span class="iconfont_Me icon-download uniformStyle"></span>
          </div>
        </div>
        <div class="buttonUniformStyle rightButton" @click="nextPicture">
          <span class="iconfont_Me icon-right uniformStyle"></span>
        </div>
      </div>
    </div>
    <div class="bottomDisplay" ref="bottomDisplay">
      <div class="innerBox">
        <div
          class="smallPicture"
          v-for="(item, index) in config.images"
          :key="index"
          :class="{ chosen: index === config.zIndex }"
          @click="selectThisPicture(index)"
        >
          <el-image
            style="width: 100%; height: 100%"
            :src="formartImageUrl(item)"
            fit="cover"
          ></el-image>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DisplayArea from "./displayArea";
import SharePopover from "../../../components/sharePopover";
import UUID from "uuidjs";
export default {
  components: {
    DisplayArea,
    SharePopover,
  },
  data() {
    return {
      // 分享所需要的参数
      shareConfig: {
        // 分享的内容类型(1:帖子,3:用户,4:群)
        share_type: 1,
        // 分享的内容Id
        share_id: this.$store.state.homePage.multimediaPopupConfig.gigId,
      },
    };
  },
  mounted() {
    document.body.parentNode.style.overflow = "hidden";
    this.scrollInit();
  },
  destroyed() {
    document.body.parentNode.style.overflow = "visible";
  },
  props: {
    // 图片配置
    config: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  methods: {
    // 向IM分享图片信息
    shareForMelinked(shareData) {
      let sharingDataInfo = {
        // 判断是否是在分享图片的标志
        isShareImage: true,
        // 图片的网络地址
        onlineurl: this.config.images[this.config.zIndex],
        // 图片的备用地址
        url: this.config.images[this.config.zIndex],
        // 分享的类型（个人：people；群组：group；工作：job；帖子：service；公司：company）
        type: "image",
        // 消息ID
        uuid: UUID.generate(),
      };
      this.$store.dispatch("commitOpenSharePopup", sharingDataInfo);
    },
    // 下载当前图片
    downloadPicture() {
      fetch(this.config.images[this.config.zIndex])
        .then((res) => res.blob())
        .then((blob) => {
          const a = document.createElement("a");
          document.body.appendChild(a);
          a.style.display = "none";
          const url = window.URL.createObjectURL(blob);
          a.href = url;
          a.download = Date.now();
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 上一张
    previousPicture() {
      if (this.config.zIndex != 0 && this.config.images.length != 0) {
        this.config.zIndex--;
      } else {
        this.config.zIndex = this.config.images.length - 1;
      }
    },
    // 下一张
    nextPicture() {
      if (this.config.zIndex != this.config.images.length - 1) {
        this.config.zIndex++;
      } else {
        this.config.zIndex = 0;
      }
    },
    // 选择展示当前图片
    selectThisPicture(index) {
      this.config.zIndex = index;
    },
    // 初始化与绑定监听事件方法
    scrollInit() {
      this.$refs.bottomDisplay.addEventListener(
        "mousewheel",
        this.scrollHandler,
        false
      );
    },
    scrollHandler(event) {
      const detail = event.wheelDelta || event.detail;
      const moveForwardStep = 1;
      const moveBackStep = -1;
      let step = 0;
      if (detail < 0) {
        step = moveForwardStep * 100;
      } else {
        step = moveBackStep * 100;
      }
      this.$refs.bottomDisplay.scrollLeft += step;
    },
  },
};
</script>
<style lang="stylus" scoped>
.multifunction
  width 100%;
  height 100vh;
  position relative;
  display flex;
  flex-flow column;
  align-items center;
  justify-content flex-start;
  .displayAreaOuter
    width 100%;
    height 100%;
    flex 1;
    min-height 0;
    display flex;
    flex-flow row;
    box-sizing border-box;
    .mainDisplayArea
      flex 1;
      height 100%;
      padding 30px;
    .sidebar
      height 100%;
      width 150px;
      position relative;
      display flex;
      flex-flow column;
      justify-content center;
      flex-shrink 0;
      .buttonUniformStyle
        width 50px;
        height 50px;
        border-radius 50%;
        background #000;
        display flex;
        align-items center;
        justify-content center;
        cursor pointer;
        .uniformStyle
          color #fff;
          font-size 24px;
      .buttonBox
        top 23px;
        width 100%;
        height 50px;
        position absolute;
        display flex;
        flex-flow row;
        align-items center;
        justify-content space-around;
      .leftButton
        margin-left 25px;
      .rightButton
        margin-left 75px;
  .bottomDisplay
    width 100%;
    height 120px;
    background #000;
    flex-shrink 0;
    overflow-y hidden;
    overflow-x auto;
    text-align center;
    white-space:nowrap;
    display flex;
    align-items center;
    &::-webkit-scrollbar {
      height 4px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 2px;
      box-shadow: inset 0 0 5px rgba(100,100,100,0.2);
      background: rgba(100,100,100,0.2);
    }
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
      border-radius: 0;
      background: rgba(0,0,0,0.5);
    }
    .innerBox
      flex 1;
      width 0;
      height 80px;
      padding-left 6px;
      .smallPicture
        display inline-block;
        width 80px;
        height 80px;
        margin-right 6px;
        background #fff;
        vertical-align: middle
      .chosen
        box-sizing border-box;
        border 3px solid #33CC66;
</style>
