var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.path != ""
    ? _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.videoLoading,
              expression: "videoLoading"
            }
          ],
          staticClass: "videoBox"
        },
        [
          _c(
            "video",
            {
              ref: "videoPlayer",
              staticStyle: { "object-fit": "contain" },
              attrs: {
                src: _vm.path,
                "webkit-playsinline": "true",
                playsinline: "true",
                "x-webkit-airplay": "true",
                "x5-video-player-type": "h5",
                "x5-video-player-fullscreen": "true",
                "x5-video-orientation": "portraint"
              },
              on: { loadedmetadata: _vm.videoLoadedmetadata }
            },
            [_vm._v("your browser does not support the video tag")]
          ),
          _c("div", { staticClass: "timeTextBlock" }, [
            _vm._v(_vm._s(_vm.totalTime))
          ])
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }