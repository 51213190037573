var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "listAreaContent" },
    [
      _c("LikesList", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.likeProviders && _vm.likeProviders.length !== 0,
            expression: "likeProviders && likeProviders.length !== 0"
          }
        ],
        attrs: { likeProviders: _vm.likeProviders }
      }),
      _c("PlaceholderMap", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.likeProviders && _vm.likeProviders.length === 0,
            expression: "likeProviders && likeProviders.length === 0"
          }
        ],
        attrs: { placeholderType: 0 }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }