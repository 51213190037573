<template>
  <div class="listAreaContainer">
    <div class="containerBox" v-show="commentsLength !== 0">
      <div
        class="listAreaContent"
        v-infinite-scroll="loadReplaysData"
        infinite-scroll-disabled="loadReplaysDisabled"
        infinite-scroll-distance="10"
        infinite-scroll-immediate-check="false"
      >
        <CommentList
          ref="commentList"
          :getCommentsParam="getCommentsParam"
          @replyMessageReceived="replyMessageReceived"
          @openSlidingWindow="openSlidingWindow"
          @getCommentCount="getCommentCount"
          :showNewComment="true"
        ></CommentList>
      </div>
      <transition name="fade">
        <MoreCommentSlidingWindow
          v-if="showInSlidingWindow"
          :comment="commentForSlidingWindow"
          @closeSlidingWindow="closeSlidingWindow"
          @replyMessageReceived="replyMessageReceived"
        >
        </MoreCommentSlidingWindow>
      </transition>
    </div>
    <PlaceholderMap
      :placeholderType="0"
      v-show="commentsLength === 0"
    ></PlaceholderMap>
  </div>
</template>
<script>
import CommentList from "../../../../components/list/commentList";
import PlaceholderMap from "../../../../components/placeholderMap";
import MoreCommentSlidingWindow from "../../../../components/comment/moreCommentSlidingWindow";
export default {
  components: {
    CommentList,
    MoreCommentSlidingWindow,
    PlaceholderMap,
  },
  data() {
    return {
      // 是否展示滑窗
      showInSlidingWindow: false,
      // 滑窗内需要的参数
      commentForSlidingWindow: null,
      // 评论列表长度(这里默认为1的原因就是为了先触发getCommentsParam的变动，如果一来就直接隐藏了，就不能再获取到评论列表的长度了)
      commentsLength: 1,
    };
  },
  watch: {
    // 判断需要刷新的评论是不是属于当前列表
    "$store.state.globalShare.needRefreshComment": {
      async handler(val, old) {
        // 判断更多评论弹窗是否是对应的当前这条评论,判断是否是关闭
        if (val && val !== "" && val !== old) {
          this.closeSlidingWindow();
        }
      },
      deep: true,
    },
  },
  props: {
    postInfo: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  computed: {
    // 帖子列表参数
    getCommentsParam() {
      return {
        // 操作对象ID
        id: this.postInfo.gigId,
        // 操作对象(1:旧版帖子评论,3:用户,4:群,5:公司,7:新版帖子评论)
        operation: 7,
        // 点赞用的type(1:帖子,3:用户,4:群)
        type: 1,
        // 这个参数表示更多评论是否用弹窗展示
        popupDisplay: false,
        // 正在加载中,禁止再触发
        loadReplaysDisabled: false,
      };
    },
  },
  methods: {
    // 获取评论列表长度
    getCommentCount(length) {
      this.commentsLength = length;
      this.$emit("refreshCommentCount", length);
    },
    // 加载更多评论列表
    async loadReplaysData() {
      this.loadReplaysDisabled = true;
      try {
        await this.$refs.commentList.getReplaysList();
      } catch (error) {}
      this.loadReplaysDisabled = false;
    },
    // 回复帖子
    replyMessageReceived(comment) {
      this.$emit("replyMessageReceived", comment);
    },
    // 打开滑窗查看更多评论
    openSlidingWindow(comment) {
      this.commentForSlidingWindow = _.cloneDeep(comment);
      this.$nextTick(() => {
        this.showInSlidingWindow = true;
      });
    },
    // 关闭滑窗
    closeSlidingWindow() {
      this.showInSlidingWindow = false;
      this.$nextTick(() => {
        this.commentForSlidingWindow = null;
      });
    },
  },
};
</script>
<style lang="stylus" scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .3s, transform .3s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
  transform translate3d(200px,0,0);
}
.listAreaContainer
  width 100%;
  height 100%;
  overflow hidden
  .containerBox
    width 100%;
    height 100%;
    overflow hidden
    .listAreaContent
      width 100%;
      height 100%;
      overflow-x hidden;
      box-sizing border-box;
      padding-left 1px;
      position relative;
      scrollbar-width: none;
      &::-webkit-scrollbar
        width 0;
</style>
