<template>
  <div class="sidebarContent" v-loading="loading">
    <div class="sidebarTopContent">
      <FirstPiece
        :postInfo="postInfo"
        :blacklistInfo="blacklistInfo"
        @changelikeStatus="changelikeStatus"
      ></FirstPiece>
      <SecondPiece
        @replyMessageReceived="replyMessageReceived"
        :postInfo="postInfo"
      ></SecondPiece>
    </div>
    <div class="sidebarFooterContent">
      <CommentInputBox
        :answerSomeone="answerSomeone"
        @deleteAnswerSomeone="deleteAnswerSomeone"
        @triggerSubmit="triggerSubmit"
        @commentInputFocus="commentInputFocus"
        :withPictures="true"
      ></CommentInputBox>
    </div>
  </div>
</template>
<script>
import FirstPiece from "./firstPiece";
import SecondPiece from "./secondPiece";
import { getPostInformation } from "@/api/newVersion/postContentDetails";
import CommentInputBox from "../../../components/comment/commentInputBox";
import { addNewReview } from "@/api/newVersion";
import * as RESTApi from "@/api/rest";
export default {
  components: {
    FirstPiece,
    SecondPiece,
    CommentInputBox,
  },
  data() {
    return {
      // 是否加载成功
      loading: false,
      answerSomeone: "",
      // 帖子详情
      postInfo: {},
      // 发布者与当前用户的黑名单关系
      blacklistInfo: {},
    };
  },
  computed: {
    // 帖子ID,方便写
    gigId() {
      return this.$store.state.homePage.multimediaPopupConfig.gigId;
    },
  },
  watch: {
    // 监听帖子ID是否变化
    gigId: {
      async handler(val, old) {
        if (val !== "0" && val !== old) {
          this.loading = true;
          await this.getPostInformation();
          this.loading = false;
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    // 子组件改变了喜欢状态，刷新数据
    async changelikeStatus() {
      this.$set(this.postInfo, "likeStatus", !this.postInfo.likeStatus);
      this.$set(
        this.postInfo,
        "likeCount",
        this.postInfo.likeStatus
          ? (Number(this.postInfo.likeCount) + 1).toString()
          : (Number(this.postInfo.likeCount) - 1).toString()
      );
      let likeProvider = {
        country: this.$store.getters.userInfo.country,
        iCanDo: "",
        providerId: this.$store.getters.userInfo.id,
        tag: "",
        time: "",
        userAvatar: this.$store.getters.userInfo.avatar,
        userName: this.$store.getters.name,
      };
      let likeProviders = this.postInfo.likeProviders;
      if (
        likeProviders.length !== 0 &&
        likeProviders.some(
          (item) => item.providerId === this.$store.getters.userInfo.id
        )
      ) {
        // 如果当前列表中有本地这个用户，就删除这个用户，否则就加入这个用户
        likeProviders = likeProviders.filter(
          (e) => e.providerId !== this.$store.getters.userInfo.id
        );
      } else {
        likeProviders.unshift(likeProvider);
      }
      this.$set(this.postInfo, "likeProviders", likeProviders);
      // 将点赞结果存入状态管理器中
      let likedRecord = {
        likeItemId: this.gigId,
        likeCount: this.postInfo.likeCount,
        likeStatus: this.postInfo.likeStatus,
      };
      this.$store.dispatch("commitSetAlreadyLikedRecords", likedRecord);
    },
    // 输入框触发事件
    commentInputFocus() {
      // 触发改变视频状态
      this.$emit("changeVideoStatus");
    },
    // 开始获取帖子的详细信息
    getPostInformation() {
      return new Promise((resolve, reject) => {
        this.postInfo = {};
        this.$nextTick(async () => {
          let result = await getPostInformation(this.gigId);
          if (result.code === 200) {
            this.postInfo = result.data.data;
            this.$emit("getIcanDo", this.postInfo.iCanDo);
            await this.getBlacklistStatus();
          } else {
            this.$message({
              type: "error",
              message: result.message,
            });
          }
          this.$nextTick(() => {
            resolve("success");
          });
        });
      });
    },
    // 获取帖子发布者对应的黑名单状态
    async getBlacklistStatus() {
      let result = await RESTApi.getBlacklistStatus([this.postInfo.providerId]);
      if (result.code == 200) {
        try {
          this.blacklistInfo = result.data.data[0];
        } catch (error) {
          console.log(error);
        }
      } else {
        this.$message({
          type: "error",
          message: result.message,
        });
      }
    },
    // 提交评论
    async triggerSubmit(param) {
      let params = {
        operation: 7,
        reply: param.commentText,
      };
      if (param.imgUrl) {
        params.imgUrl = param.imgUrl;
      }
      let result = await addNewReview(this.gigId, params);
      if (result.code === 200) {
        if (!this.inReplyComment) {
          this.$store.commit("setNeedRefreshOperationComment", this.gigId);
        }
        this.postInfo.commentCount = (
          Number(this.postInfo.commentCount) + 1
        ).toString();
        let latestComment = {
          commentId: this.gigId,
          commentCount: this.postInfo.commentCount,
        };
        this.$store.dispatch("commitSetListOfLatestComments", latestComment);
        this.$nextTick(() => {
          this.deleteAnswerSomeone();
        });
      } else {
        this.$message({
          type: "error",
          message: result.message,
        });
      }
    },
    // 接收到回复事件
    replyMessageReceived(comment) {
      this.answerSomeone = comment.reviewer;
    },
    // 删除要回复的人(重置输入框数据)
    deleteAnswerSomeone() {
      this.answerSomeone = "";
    },
  },
};
</script>
<style lang="stylus" scoped>
.sidebarContent
  width 100%;
  height 100%;
  display flex;
  flex-flow column;
  align-items center;
  justify-content flex-start;
  box-sizing border-box;
  background #FFFFFF;
  transition width .3s, opacity .3s;
  .sidebarTopContent
    width 100%;
    flex 1;
    min-height 0;
    display flex;
    flex-flow column;
    align-items center;
    justify-content flex-start;
    box-sizing border-box;
    padding 24px 24px 0 24px;
  .sidebarFooterContent
    width 100%;
    min-height 55px;
    max-height 20.8vh;
    border-top 1px solid #F0F0F0;
    padding 15px 24px;
    box-sizing border-box;
    flex-shrink 0;
</style>
<style lang="stylus">
.sidebarContent
  .el-loading-mask
    background rgba(255,255,255,1) !important;
    display flex;
    align-items center;
    justify-content center;
    .el-loading-spinner
      position relative;
      margin-top 0;
      top 0;
</style>
