<template>
  <div class="firstPiece">
    <div class="firstRow">
      <div class="infoForUser">
        <PostHeader :headerData="headerData" :vipStatus="headerData.vipStatus" :showViewCount="false"></PostHeader>
      </div>
      <div class="operatingArea">
        <div class="goChatButton commonButton commomButtonHover" @click.stop="startChatting">
          <span class="iconfont_Me icon-information-fill buttonStyle"></span>
        </div>
        <div class="operateFriends commonButton" @click.stop="changeFriendStatus" v-loading="friendStatusLoading"
          element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)"
          v-if="postInfo.providerId !== $store.getters.userInfo.id" :style="{
            background:
              blacklistButtonStatus && !postInfo.friendStatus ? '#B3B3B3' : '',
            cursor:
              blacklistButtonStatus && !postInfo.friendStatus
                ? 'not-allowed'
                : '',
          }">
          <span class="iconfont_Me buttonStyle" :class="
            postInfo.friendStatus
              ? 'icon-deleteuser-fill'
              : 'icon-Adduser-fill'
          "></span>
        </div>
      </div>
    </div>
    <TextDisplay v-if="postInfo.detail" :postDetail="postDetail" @jumpToPage="jumpToPage"></TextDisplay>
    <div class="labelRow" v-if="tags.length !== 0">
      <Label v-for="(item, index) in tags" :key="index + item" :labelContent="item"></Label>
    </div>
    <PostFooter :footerInfo="footerInfo" @changelikeStatus="changelikeStatus" @jumpToPage="jumpToPage"></PostFooter>
  </div>
</template>
<script>
import PostHeader from "../../../../components/postHeader";
import TextDisplay from "../../../../components/textDisplay";
import Label from "../../../../components/label";
import PostFooter from "../../../../components/postFooter";
import { deleteFriend } from "@/api/newVersion";
export default {
  components: {
    PostHeader,
    TextDisplay,
    Label,
    PostFooter,
  },
  props: {
    // 帖子信息
    postInfo: {
      type: Object,
      default: () => {
        return {};
      },
    },
    // 黑名单信息
    blacklistInfo: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  computed: {
    // 添加好友按钮显示的状态
    blacklistButtonStatus() {
      let blacklistButtonStatus = false;
      try {
        blacklistButtonStatus =
          this.blacklistInfo.positiveStatus || this.blacklistInfo.reverseStatus;
      } catch (e) {
        console.log(e);
      }
      return blacklistButtonStatus;
    },
    // 根据不同的黑名单关系展示不同的提示
    blacklistMessage() {
      let blacklistMessage = "";
      try {
        // 本地用户将此用户拉入黑名单
        if (this.blacklistInfo.positiveStatus) {
          blacklistMessage = this.$t("blackListPositiveStatus");
          // 此用户将本地用户拉入黑名单
        } else if (this.blacklistInfo.reverseStatus) {
          blacklistMessage = this.$t("blackListReverseStatus");
        }
      } catch (error) { }
      return blacklistMessage;
    },
    // 头部信息数据
    headerData() {
      return {
        name: this.postInfo.name,
        country: this.postInfo.country != "" ? this.postInfo.country : 0,
        profilePhoto: this.postInfo.profilePhoto,
        providerId: this.postInfo.providerId,
        publishTime: this.postInfo.publishTime,
        pageViewCount: this.postInfo.pageViewCount,
        vipStatus: this.postInfo.vipStatus
      };
    },
    // 帖子详情数组结构
    postDetail() {
      return this.postInfo.detail;
    },
    // 帖子详情富文本数据
    postRichDetail() {
      return this.postInfo.richDetail;
    },
    // 帖子标签
    tags() {
      let tags = [];
      if (this.postInfo.tags && this.postInfo.tags !== "") {
        tags = this.postInfo.tags.split(",");
      }
      return tags;
    },
    // 帖子底部信息
    footerInfo() {
      return {
        pageViewCount: this.postInfo.pageViewCount,
        commentCount: this.postInfo.commentCount,
        likeCount: this.postInfo.likeCount,
        likeStatus: this.postInfo.likeStatus,
        gigId: this.postInfo.gigId,
        postInfo: this.postInfo,
        type: 1,
      };
    },
  },
  data() {
    return {
      // 删除好友的状态
      friendStatusLoading: false,
    };
  },
  methods: {
    // 改变喜欢状态
    changelikeStatus() {
      this.$emit("changelikeStatus");
    },
    // 开始聊天
    async startChatting() {
      if (!this.$store.getters.userInfo.id) {
        this.$store.commit("setLoginAndRegisterDialog", true);
        return;
      }
      this.chatWithAuthor(
        {
          thumb: this.postInfo.profilePhoto,
          name: this.postInfo.name,
          id: this.postInfo.providerId,
        },
        "user"
      );
    },
    // 改变好友状态
    changeFriendStatus() {
      // 只拦截可添加好友状态
      if (this.blacklistButtonStatus && !this.postInfo.friendStatus) {
        this.$message({
          type: "error",
          message: this.blacklistMessage,
        });
        return;
      }
      if (this.postInfo.friendStatus) {
        this.deleteFriend();
      } else {
        this.modifyFriendInformation();
      }
    },
    // 删除好友
    async deleteFriend() {
      if (!this.$store.getters.userInfo.id) {
        this.$store.commit("setLoginAndRegisterDialog", true);
        return;
      }
      this.$confirm("", "", {
        confirmButtonText: " ",
        cancelButtonText: " ",
        type: "warning",
        center: true,
        iconClass: "el-icon-delete",
        customClass: "my-confirm",
        cancelButtonClass: "el-icon-close my-confirm-btn",
        confirmButtonClass: "el-icon-check my-confirm-btn",
      })
        .then(async () => {
          let params = {
            userId: this.$store.getters.userInfo.id,
            friendId: this.postInfo.providerId,
            language: localStorage.getItem("langCode"),
          };
          this.friendStatusLoading = true;
          let result = await deleteFriend(params);
          this.friendStatusLoading = false;
          if (result.code) {
            this.postInfo.friendStatus = !this.postInfo.friendStatus;
          } else {
            this.$message({
              type: "error",
              message: result.message,
            });
          }
        })
        .catch((e) => { });
    },
    // 添加好友关系
    modifyFriendInformation() {
      if (!this.$store.getters.userInfo.id) {
        this.$store.commit("setLoginAndRegisterDialog", true);
        return;
      }
      let userInfoForAddFriend = {
        id: this.postInfo.providerId,
        avatar: this.postInfo.profilePhoto,
        name: this.postInfo.name,
      };
      this.$store.dispatch("commitOpenAddFriendPopup", userInfoForAddFriend);
    },
    // 跳转详情
    jumpToPage() {
      this.$store.commit("setVideoRecording", []);
      this.$store.dispatch("commitClosemultimediaPopup");
      this.$nextTick(() => {
        this.routeJump({
          name: "postContentDetails",
          params: { id: this.postInfo.gigId },
        });
      });
    },
  },
};
</script>
<style lang="stylus" scoped>
.firstPiece {
  width: 100%;
  min-height: 0;
  flex-shrink: 0;
  box-sizing: border-box;
  padding-bottom: 10px;
  border-bottom: 1px solid #F0F0F0;

  .firstRow {
    width: 100%;
    height: 50px;
    margin-bottom: 15px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .infoForUser {
      height: 50px;
      flex: 1;
      overflow: hidden;
      min-width: 0;
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }

    .operatingArea {
      height: 50px;
      width: 116px;
      flex-shrink: 0;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .commonButton {
        height: 40px;
        border-radius: 6px;
        overflow: hidden;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;

        .buttonStyle {
          font-size: 24px;
        }
      }

      .goChatButton {
        width: 70px;

        .buttonStyle {
          color: #fff;
        }
      }

      .operateFriends {
        width: 40px;
        margin-left: 6px;
        background: #E6E6E6;

        .buttonStyle {
          color: #666666;
        }
      }
    }
  }

  .labelRow {
    width: 100%;
    max-height: 68px;
    margin-top: 10px;
    line-height: 34px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }
}
</style>