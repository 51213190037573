<template>
  <div
    class="largeVideoPlayer"
    v-if="config.path != ''"
    v-loading="videoLoading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
  >
    <div class="fullscreenTitle" v-if="isFullscreen">{{ postTitle }}</div>
    <video
      ref="videoPlayer"
      :src="config.path"
      webkit-playsinline="true"
      playsinline="true"
      x-webkit-airplay="true"
      x5-video-player-type="h5"
      x5-video-player-fullscreen="true"
      x5-video-orientation="portraint"
      style="object-fit:contain"
      @loadedmetadata="videoLoadedmetadata"
      @timeupdate="videoTimeupdate"
      @error="videoError"
    >
      your browser does not support the video tag
    </video>
    <div
      v-if="!finishedPlayingMarkFlag"
      class="videoOperationLayer"
      :style="{
        opacity: movementVideo || isShowOnFloatingWindow ? 1 : 0,
        background: isShowOnFloatingWindow ? 'rgba(0,0,0,0.5)' : 'transparent',
      }"
      @click="playVideo"
      @mouseenter="videoMouseenter"
      @mouseleave="videoMouseleave"
    >
      <div
        class="videoStatus"
        @click.stop="playVideo"
        v-if="!isNowPlaying && !isShowOnFloatingWindow"
      >
        <span class="iconfont_Me icon-play2-fill videoStatusIconStyle"></span>
      </div>
      <div class="videoStatus" v-if="isShowOnFloatingWindow">
        <span class="iconfont_Me icon-zoomout videoStatusIconStyle"></span>
      </div>
      <div
        class="videoControl largeControl"
        v-if="!isShowOnFloatingWindow"
        @click.stop="() => {}"
      >
        <div
          class="sliderOuter"
          @mouseenter="sliderMouseenter"
          @mouseleave="sliderMouseleave"
        >
          <el-slider
            v-model="currentTimeVal"
            :class="movementSlider ? 'movementOn' : 'movementOff'"
            @change="changeSlider"
            :show-input-controls="false"
            :show-tooltip="false"
            :max="durationProgress"
          ></el-slider>
        </div>
        <div class="controlInner">
          <div class="controlLeft">
            <div class="smallVideoStatus" @click.stop="playVideo">
              <span
                class="iconfont_Me videoStatusIconStyle"
                :class="!isNowPlaying ? 'icon-play2-fill' : 'icon-zanting'"
              ></span>
            </div>
            <div class="timeTextShow">{{ currentTime }}/{{ totalTime }}</div>
          </div>
          <div class="controlRight">
            <div
              class="controlButton"
              v-if="config.floatingWindowPlay"
              @click="setfloatingWindow"
            >
              <span
                class="iconfont_Me buttonStyle"
                :class="isShowOnFloatingWindow ? 'icon-zoom' : 'icon-zoomout'"
              ></span>
            </div>
            <div
              class="controlButton"
              v-if="config.zoomControl"
              @click="toggleFullScreen"
            >
              <span
                class="iconfont_Me buttonStyle"
                :class="isFullscreen ? 'icon-narrow1' : 'icon-Fullscreen'"
              ></span>
            </div>
            <div
              class="controlButton"
              v-if="config.downloadControl"
              @click="downLoadVideo"
            >
              <span class="iconfont_Me icon-download buttonStyle"></span>
            </div>
            <div
              class="controlButton volumeButton"
              @click="openVolumePopover"
              v-if="config.volumeControl"
            >
              <span
                class="iconfont_Me buttonStyle"
                :class="volume != 0 ? 'icon-un-mute' : 'icon-Mute'"
              ></span>
              <div class="volumePopover" v-if="volumePopover">
                <el-slider
                  vertical
                  height="70px"
                  v-model="volume"
                  :max="100"
                  @input="changeVolume"
                ></el-slider>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="finishedPlayingMark" v-if="finishedPlayingMarkFlag">
      <div class="markInnerBox">
        <div class="innerButton buttonStyle" @click="playPreviousVideo">
          <span class="iconfont_Me icon-Prevvideo iconStyle"></span>
        </div>
        <div class="innerButton buttonStyle" @click="replayVideo">
          <span class="iconfont_Me icon-replay iconStyle"></span>
        </div>
        <div class="buttonOuter" @click="playNextVideo">
          <el-progress
            type="circle"
            :percentage="handlePercentage"
            :stroke-width="3"
            :width="56"
            :show-text="false"
            :color="customColor"
          ></el-progress>
          <div class="innerButton">
            <span class="iconfont_Me icon-nextvideo iconStyle"></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      movementSlider: false, // 鼠标移动上进度条
      movementVideo: false, // 鼠标移动到视频上
      videoMouseTimer: null, // 鼠标是否移出视频的定时器
      videoLoading: false, // 视频初始化
      videoPlayer: null, // 当前视频播放器对象
      volume: 50, // 默认音量0.5
      volumePopover: false, // 音频操作版面
      isNowPlaying: false, // 标记当前的播放状态,默认视频暂停状态
      currentTime: "00:00", // 当前播放时间
      totalTime: "00:00", // 总时长
      currentTimeVal: 0, // 进度条当前播放时间，必须是number类型
      durationProgress: 0, // 进度条的最大值，必须是number类型
      downloading: false, //正在下载,
      customColor: "#FFFFFF", //播放下一个视频进度条
      progressPercentage: 0, //播放下一个视频的时间进度
      requestAnimationFrameTimer: null, //帧定时器对象
      progressDuration: 5000, //环线进度条时长
      finishedPlayingMarkFlag: false, //视频播放完成后的遮罩层状态
    };
  },
  props: {
    // 播放器配置信息
    config: {
      type: Object,
      default: () => {
        return {
          // 视频地址
          path: "",
          // 视频播放进度（秒）
          currentTime: 0,
          // 是否显示浮窗控制按钮
          floatingWindowPlay: false,
          // 是否展示放大按钮
          zoomControl: false,
          // 是否展示下载按钮
          downloadControl: false,
          // 音量控制按钮是否展示
          volumeControl: false,
        };
      },
    },
    // 是否处于全屏状态
    isFullscreen: {
      type: Boolean,
      default: false,
    },
    // 帖子标题
    postTitle: {
      type: String,
      default: "",
    },
  },
  computed: {
    // 这个是判断悬浮窗是否打开，并且悬浮窗中播放的是不是当前组件的视频
    isShowOnFloatingWindow() {
      return (
        this.$store.state.videoRelated.videoFloatingWindow.path &&
        this.$store.state.videoRelated.videoFloatingWindow.path ===
          this.config.path
      );
    },
    // 处理过的环形进度条
    handlePercentage() {
      let progressPercentage = 0;
      if (this.progressPercentage > 100) {
        progressPercentage = 100;
      } else {
        progressPercentage = parseInt(this.progressPercentage);
      }
      return progressPercentage;
    },
  },
  watch: {
    isShowOnFloatingWindow: {
      handler(val, old) {
        // 监听当前组件对应的视频从悬浮窗中撤出，是否可以继续播放
        if (val != old && val == false) {
          this.videoPlayer.currentTime = this.$store.state.videoRelated.timeRecord;
          this.$nextTick(() => {
            this.isNowPlaying = true;
          });
        }
      },
      deep: true,
    },
    isNowPlaying: {
      handler(val) {
        if (val && this.videoPlayer) {
          this.videoPlayer.play();
        } else if (!val && this.videoPlayer) {
          this.videoPlayer.pause();
        }
      },
      deep: true,
    },
    // 监听视频地址变化
    "config.path": {
      handler(val, old) {
        if (val && val !== old) {
          // 先清除定时器
          if (this.requestAnimationFrameTimer) {
            window.cancelAnimationFrame(this.requestAnimationFrameTimer);
          }
          // 重置所有条件
          Object.assign(this.$data, this.$options.data.call(this));
        }
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    this.videoLoading = true;
  },
  beforeDestroy() {
    if (this.videoMouseTimer) {
      clearTimeout(this.videoMouseTimer);
    }
    if (this.requestAnimationFrameTimer) {
      window.cancelAnimationFrame(this.requestAnimationFrameTimer);
    }
  },
  methods: {
    // 视频加载错误
    videoError() {
      this.$message({
        type: "error",
        message: "load error",
      });
    },
    // 点击播放上一个视频
    playPreviousVideo() {
      window.cancelAnimationFrame(this.requestAnimationFrameTimer);
      // 通知上层组件可以开始播放下一个视频了
      this.$emit("canStartPlayingThePreviousVideo");
    },
    // 点击重播视频
    replayVideo() {
      window.cancelAnimationFrame(this.requestAnimationFrameTimer);
      this.progressPercentage = 0;
      this.finishedPlayingMarkFlag = false;
      this.$nextTick(() => {
        this.isNowPlaying = true;
      });
    },
    // 点击播放下一个视频
    playNextVideo() {
      window.cancelAnimationFrame(this.requestAnimationFrameTimer);
      this.progressPercentage = 0;
      // 通知上层组件可以开始播放下一个视频了
      this.$emit("canStartPlayingTheNextVideo");
    },
    // 定时器，按帧数进行循环渲染环线进度条
    runProgressAnimate() {
      window.cancelAnimationFrame(this.requestAnimationFrameTimer);
      if (this.progressPercentage < 100) {
        this.progressPercentage =
          this.progressPercentage + 1000 / 60 / (this.progressDuration / 100);
        this.$nextTick(() => {
          this.requestAnimationFrameTimer = window.requestAnimationFrame(() => {
            this.runProgressAnimate();
          });
        });
      } else {
        // 通知上层组件可以开始播放下一个视频了
        this.$emit("canStartPlayingTheNextVideo");
      }
    },
    // 点击进入浮窗界面
    setfloatingWindow() {
      this.isNowPlaying = false;
      this.$nextTick(() => {
        // 如果悬浮窗打开且播放的视频不是本组件的视频，或者悬浮窗处于关闭状态，则开启悬浮播放
        if (!this.isShowOnFloatingWindow && !this.videoLoading) {
          let config = {
            // 视频地址
            path: this.config.path,
            // 当前播放的进度
            currentTime: this.videoPlayer.currentTime,
          };
          this.$store.commit("setVideoFloatingWindow", config);
        }
      });
    },
    // 如果是悬浮窗，点击关闭悬浮窗
    closeFloatingWindow() {
      this.isNowPlaying = false;
      this.$nextTick(() => {
        // 必须写前面
        this.$store.commit("setTimeRecord", this.videoPlayer.currentTime);
        let config = {
          // 视频地址
          path: "",
          // 当前播放的进度
          currentTime: 0,
        };
        this.$store.commit("setVideoFloatingWindow", config);
      });
    },
    // 视频元数据加载成功，可以开始播放，并可以使用video对象的数据
    videoLoadedmetadata() {
      this.videoLoading = false;
      this.videoPlayer = this.$refs.videoPlayer;
      this.initVideo();
    },
    // 初始化视频空间
    initVideo() {
      // 获取视频基本信息
      this.getTotal();
    },
    // 开始播放视频
    playVideo() {
      // 如果当前视频已经转移到悬浮窗播放，则禁止再操作这视频；如果当前就是在悬浮窗，则可以继续操作
      if (this.isShowOnFloatingWindow) return;
      if (!this.videoPlayer) return;
      this.isNowPlaying = !this.isNowPlaying;
    },
    // 鼠标移入视频
    videoMouseenter() {
      if (this.videoMouseTimer) {
        clearTimeout(this.videoMouseTimer);
      }
      this.$nextTick(() => {
        this.movementVideo = true;
      });
    },
    // 鼠标移出视频
    videoMouseleave() {
      this.videoMouseTimer = setTimeout(() => {
        this.movementVideo = false;
      }, 1500);
    },
    // 点击音频控制按钮
    openVolumePopover() {
      this.volumePopover = !this.volumePopover;
    },
    // 改变音量
    changeVolume() {
      this.videoPlayer.volume = this.volume / 100;
    },
    // 鼠标移入进度条范围
    sliderMouseenter() {
      this.movementSlider = true;
    },
    // 鼠标移出进度条范围
    sliderMouseleave() {
      this.movementSlider = false;
    },
    // 改变进度条
    changeSlider(e) {
      if (!this.videoPlayer) return;
      this.isNowPlaying = false;
      this.$nextTick(() => {
        this.videoPlayer.currentTime = this.currentTimeVal;
        this.isNowPlaying = true;
      });
    },
    //时间格式化处理
    timeFormat(time) {
      let minute = Math.floor((time % 3600) / 60);
      let second = Math.floor(time % 60);
      minute = minute < 10 ? "0" + minute : minute;
      second = second < 10 ? "0" + second : second;
      return `${minute}:${second}`;
    },
    //获取总时长
    getTotal() {
      // 获取过滤后的时间文本
      this.totalTime = this.timeFormat(this.videoPlayer.duration);
      // 获取视频总时长时间戳
      this.durationProgress = this.videoPlayer.duration;
      if (this.config.currentTime != 0) {
        this.$nextTick(() => {
          this.videoPlayer.currentTime = this.config.currentTime;
          this.$nextTick(() => {
            this.isNowPlaying = true;
          });
        });
      }
    },
    //获取当前视频播放到的时间
    videoTimeupdate() {
      if (!this.videoPlayer) return;
      if (this.videoPlayer.currentTime == this.videoPlayer.duration) {
        this.isNowPlaying = false;
        this.finishedPlayingMarkFlag = true;
        this.$nextTick(() => {
          this.runProgressAnimate();
        });
      }
      this.currentTime = this.timeFormat(this.videoPlayer.currentTime);
      this.currentTimeVal = this.videoPlayer.currentTime;
      this.$store.commit("setRecordPlaybackProgress", this.currentTimeVal);
    },
    // 下载视频
    downLoadVideo() {
      return new Promise((resolve, reject) => {
        this.videoLoading = true;
        fetch(this.config.path)
          .then((res) => res.blob())
          .then((blob) => {
            this.videoLoading = false;
            const a = document.createElement("a");
            document.body.appendChild(a);
            a.style.display = "none";
            const url = window.URL.createObjectURL(blob);
            a.href = url;
            a.download = Date.now();
            a.click();
            a.remove();
            window.URL.revokeObjectURL(url);
            resolve("success");
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    },
    //开大屏
    toggleFullScreen() {
      this.$emit("toggleFullScreen");
    },
  },
};
</script>
<style lang="stylus" scoped>
.largeVideoPlayer
  width 100%;
  height 100%;
  border-radius 8px;
  overflow hidden;
  position relative;
  background rgba(0,0,0,0.9)
  .fullscreenTitle
    top 30px;
    left 30px;
    position absolute;
    display inline-block;
    color #FFFFFF;
    font-size 18px;
    font-weight bold;
  video
    width 100%;
    height 100%;
  .videoOperationLayer
    width 100%;
    height 100%;
    position absolute;
    top 0;
    left 0;
    z-index 2
    display flex;
    align-items center;
    justify-content center;
    transition opacity 0.5s;
    .deleteThisVideo
      top 5px;
      right 5px;
      width 18px;
      height 18px;
      z-index 3;
      border-radius 50%;
      position absolute;
      background rgba(0,0,0,0.2);
      display flex;
      align-items center;
      justify-content center;
      cursor pointer;
      .deleteStyle
        font-size 12px;
        color #ffffff;
    .videoStatus
      width 50px;
      height 50px;
      border-radius 50%;
      background rgba(0,0,0,0.5);
      transition transform .2s , background .2s;
      cursor pointer;
      text-align center;
      line-height 50px;
      &:hover
        background rgba(0,0,0,0.7);
      .videoStatusIconStyle
        font-size 26px;
        color #ffffff;
  .finishedPlayingMark
    top 0;
    left 0;
    z-index 2
    width 100%;
    height 100%
    display flex;
    position absolute;
    align-items center;
    justify-content center;
    transition opacity 0.5s;
    background rgba(0,0,0,0.5);
    .markInnerBox
      width 400px;
      height 56px;
      display flex;
      align-items center;
      justify-content space-between;
      .buttonOuter
        width 56px;
        height 56px;
        border-radius 50%;
        display flex;
        align-items center;
        justify-content center;
        position relative;
        transition transform .5s;
        &:hover
          transform scale(1.2)
        .innerButton
          top 50%;
          left 50%;
          position absolute;
          transform translate3d(-50%,-50%,0);
      .buttonStyle
        &:hover
          transform scale(1.2)
      .innerButton
        width 50px;
        height 50px;
        border-radius 50%;
        background rgba(0,0,0,0.4);
        cursor pointer;
        display flex;
        align-items center;
        justify-content center;
        transition transform .5s;
        .iconStyle
          color #FFFFFF;
          font-size 28px;
.videoControl
  width 100%;
  position absolute;
  left 0;
  bottom 0;
  background: linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,0.5));
  display flex;
  align-items center;
  justify-content center;
  .sliderOuter
    left 0;
    top -4px;
    width 100%;
    height 10px;
    position absolute;
    display flex;
    align-items center;
    justify-content center;
  .controlInner
    display flex;
    align-items center;
    justify-content space-between;
    .controlLeft
      height 100%;
      display flex;
      align-items center;
      justify-content flex-start;
      .smallVideoStatus
        display flex;
        cursor pointer;
        align-items center;
        justify-content center;
        .videoStatusIconStyle
          color #fff;
          font-size 24px !important;
    .controlRight
      height 100%;
      display flex;
      align-items center;
      justify-content flex-start;
      .controlButton
        display flex;
        align-items center;
        justify-content center;
        cursor pointer;
        .buttonStyle
          color #fff;
          font-size 24px;
      .volumeButton
        position relative;
        .volumePopover
          width 30px;
          height 90px;
          position absolute;
          top -105px;
          left 50%;
          transform translate3d(-20px,0,0);
          background rgba(0,0,0,0.5);
          border-radius 4px;
          display flex;
          align-items center;
          justify-content center;
.largeControl
  height 48px;
  .controlInner
    height 24px;
    width 100%;
    padding 0 16px;
    box-sizing border-box;
    .controlLeft
      .smallVideoStatus
        width 24px;
        height 24px;
      .timeTextShow
        color #fff;
        font-size 14px;
        margin-left 18px;
    .controlRight
      .controlButton
        width 24px;
        height 24px;
        margin-left 31px;
        &:first-child
          margin-left 0 !important;
</style>
<style lang="stylus">
.finishedPlayingMark
  .el-progress-circle
    background transparent !important;
  .el-progress-circle__track
    stroke: rgba(255,255,255,0.3) !important;
  .el-progress-circle__path
    transition stroke-dasharray 0s ease 0s, stroke 0s ease 0s !important;
.videoControl
  .sliderOuter
    .movementOn
      .el-slider__runway,.el-slider__bar
        height 4px;
      .el-slider__button-wrapper
        opacity 1;
    .movementOff
      .el-slider__runway,.el-slider__bar
        height 2px;
      .el-slider__button-wrapper
        opacity 0;
    .el-slider
      width 100%;
      .el-slider__runway
        margin 0;
        background rgba(255,255,255,0.49);
        .el-slider__bar
          border-radius 0;
        .el-slider__button-wrapper
          height: 30px;
          width: 30px;
          top -13px;
          transition opacity .2s;
          .el-slider__button
            width: 10px;
            height: 10px;
            border none;
            background #33cc66;
            border-radius: 50%;
            -webkit-transition: .2s;
            transition: .2s;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
  .volumePopover
    .el-slider
      .el-slider__runway
        margin 0;
        width 4px
        border-radius 2px;
        background rgba(255,255,255,1);
        .el-slider__bar
          border-radius 2px;
          width: 4px;
        .el-slider__button-wrapper
          height: 30px;
          width: 30px;
          left -13px;
          transition opacity .2s;
          .el-slider__button
            width: 10px;
            height: 10px;
            border none;
            background #33cc66;
            border-radius: 50%;
            -webkit-transition: .2s;
            transition: .2s;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
</style>
