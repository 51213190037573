var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "multifunction" }, [
    _c("div", { staticClass: "displayAreaOuter" }, [
      _c("div", { staticClass: "sidebar" }, [
        _c(
          "div",
          {
            staticClass: "buttonUniformStyle leftButton",
            on: { click: _vm.previousPicture }
          },
          [_c("span", { staticClass: "iconfont_Me icon-left uniformStyle" })]
        )
      ]),
      _c(
        "div",
        { staticClass: "mainDisplayArea" },
        [_c("DisplayArea", { attrs: { params: _vm.config } })],
        1
      ),
      _c("div", { staticClass: "sidebar" }, [
        _c(
          "div",
          { staticClass: "buttonBox" },
          [
            _c(
              "SharePopover",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.config.showShare,
                    expression: "config.showShare"
                  }
                ],
                attrs: { shareConfig: _vm.shareConfig },
                on: { shareForMelinked: _vm.shareForMelinked }
              },
              [
                _c("div", { staticClass: "buttonUniformStyle" }, [
                  _c("span", {
                    staticClass: "iconfont_Me icon-share2 uniformStyle"
                  })
                ])
              ]
            ),
            _c(
              "div",
              {
                staticClass: "buttonUniformStyle",
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    return _vm.downloadPicture.apply(null, arguments)
                  }
                }
              },
              [
                _c("span", {
                  staticClass: "iconfont_Me icon-download uniformStyle"
                })
              ]
            )
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass: "buttonUniformStyle rightButton",
            on: { click: _vm.nextPicture }
          },
          [_c("span", { staticClass: "iconfont_Me icon-right uniformStyle" })]
        )
      ])
    ]),
    _c("div", { ref: "bottomDisplay", staticClass: "bottomDisplay" }, [
      _c(
        "div",
        { staticClass: "innerBox" },
        _vm._l(_vm.config.images, function(item, index) {
          return _c(
            "div",
            {
              key: index,
              staticClass: "smallPicture",
              class: { chosen: index === _vm.config.zIndex },
              on: {
                click: function($event) {
                  return _vm.selectThisPicture(index)
                }
              }
            },
            [
              _c("el-image", {
                staticStyle: { width: "100%", height: "100%" },
                attrs: { src: _vm.formartImageUrl(item), fit: "cover" }
              })
            ],
            1
          )
        }),
        0
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }