var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "infinite-scroll",
          rawName: "v-infinite-scroll",
          value: _vm.loadData,
          expression: "loadData"
        }
      ],
      staticClass: "listAreaContent",
      attrs: {
        "infinite-scroll-disabled": "loadDisabled",
        "infinite-scroll-distance": "10",
        "infinite-scroll-immediate-check": "false"
      }
    },
    [
      _vm.loadingFlag
        ? _c("div", {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loadingFlag,
                expression: "loadingFlag"
              }
            ],
            staticClass: "loadingContainer",
            attrs: {
              "element-loading-spinner": "el-icon-loading",
              "element-loading-background": "#FFFFFF"
            }
          })
        : _vm._e(),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.loadingFlag && _vm.listData.length !== 0,
              expression: "!loadingFlag && listData.length !== 0"
            }
          ],
          staticClass: "listContent"
        },
        [
          _c(
            "transition-group",
            { attrs: { name: "list", tag: "div" } },
            _vm._l(_vm.listData, function(item, index) {
              return _c(
                "div",
                {
                  key: item.attachments[0].path + index,
                  staticClass: "listItem",
                  on: {
                    click: function($event) {
                      return _vm.selectedThisVideo(item)
                    }
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "videoBlock" },
                    [
                      _c("el-image", {
                        staticStyle: { width: "100%", height: "100%" },
                        attrs: {
                          src: `${item.attachments[0].path}?vframe/jpg/offset/0`,
                          fit: "contain"
                        }
                      }),
                      item.attachments[0].duration !== ""
                        ? _c("div", { staticClass: "timeTextBlock" }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.timeFormat(item.attachments[0].duration)
                                ) +
                                "\n          "
                            )
                          ])
                        : _vm._e()
                    ],
                    1
                  ),
                  _c("div", { staticClass: "postInfoBlock" }, [
                    _c("div", { staticClass: "postTitle" }, [
                      _vm._v(_vm._s(item.iCanDo ? item.iCanDo : ""))
                    ]),
                    _c("div", { staticClass: "postAuthor" }, [
                      _vm._v(_vm._s(item.name))
                    ]),
                    _c("div", { staticClass: "viewsAndDays" }, [
                      _c("div", { staticClass: "viewsBox" }, [
                        _c("div", { staticClass: "viewsIcon" }, [
                          _c("span", {
                            staticClass: "iconfont_Me icon-Views iconStyle"
                          })
                        ]),
                        _c("div", { staticClass: "viewsText" }, [
                          _vm._v(
                            "\n                " +
                              _vm._s(
                                _vm.handleNewVersionCount(item.pageViewCount)
                              ) +
                              "\n              "
                          )
                        ])
                      ]),
                      _c("div", { staticClass: "daysText" }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(item.publishTime) +
                            "\n            "
                        )
                      ])
                    ])
                  ])
                ]
              )
            }),
            0
          )
        ],
        1
      ),
      _c("PlaceholderMap", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.loadingFlag && _vm.listData.length == 0,
            expression: "!loadingFlag && listData.length == 0"
          }
        ],
        attrs: { placeholderType: 0 }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }