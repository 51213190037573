<template>
  <div
    class="listAreaContent"
    v-infinite-scroll="loadData"
    infinite-scroll-disabled="loadDisabled"
    infinite-scroll-distance="10"
    infinite-scroll-immediate-check="false"
  >
    <div
      v-if="loadingFlag"
      class="loadingContainer"
      v-loading="loadingFlag"
      element-loading-spinner="el-icon-loading"
      element-loading-background="#FFFFFF"
    ></div>
    <div class="listContent" v-show="!loadingFlag && listData.length !== 0">
      <transition-group name="list" tag="div">
        <div
          class="listItem"
          v-for="(item, index) in listData"
          :key="item.attachments[0].path + index"
          @click="selectedThisVideo(item)"
        >
          <div class="videoBlock">
            <el-image
              style="width: 100%; height: 100%"
              :src="`${item.attachments[0].path}?vframe/jpg/offset/0`"
              :fit="'contain'"
            ></el-image>
            <div
              class="timeTextBlock"
              v-if="item.attachments[0].duration !== ''"
            >
              {{ timeFormat(item.attachments[0].duration) }}
            </div>
          </div>
          <div class="postInfoBlock">
            <div class="postTitle">{{ item.iCanDo ? item.iCanDo : "" }}</div>
            <div class="postAuthor">{{ item.name }}</div>
            <div class="viewsAndDays">
              <div class="viewsBox">
                <div class="viewsIcon">
                  <span class="iconfont_Me icon-Views iconStyle"></span>
                </div>
                <div class="viewsText">
                  {{ handleNewVersionCount(item.pageViewCount) }}
                </div>
              </div>
              <div class="daysText">
                {{ item.publishTime }}
              </div>
            </div>
          </div>
        </div>
      </transition-group>
    </div>
    <PlaceholderMap
      :placeholderType="0"
      v-show="!loadingFlag && listData.length == 0"
    ></PlaceholderMap>
  </div>
</template>
<script>
import VideoBlock from "../../../../components/videoPlayer/showOnly";
import { getRecommendedList } from "@/api/newVersion/postContentDetails";
import PlaceholderMap from "../../../../components/placeholderMap";
export default {
  components: {
    VideoBlock,
    PlaceholderMap,
  },
  data() {
    return {
      // 正在加载中,禁止再触发
      loadDisabled: false,
      listData: [],
      // 当前展示页面
      pageNum: 1,
      // 一页展示数量
      pageSize: 20,
      // 展示加载loading组件
      loadingFlag: false,
    };
  },
  props: {
    postInfo: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  watch: {
    // 监听帖子ID的变化
    "postInfo.gigId": {
      handler(val, old) {
        if (val && val !== old) {
          this.loadingFlag = true;
          this.$forceUpdate();
          this.$nextTick(async () => {
            this.listData = [];
            this.pageNum = 1;
            await this.getRecommendedList();
            this.loadingFlag = false;
          });
        }
      },
      deep: true,
    },
    // 监听是否可以播放下一个视频，并将状态重置
    "$store.state.globalShare.canStartPlayingTheNextVideo": {
      handler(val, old) {
        if (val && val !== old) {
          if (
            this.listData.length !== 0 &&
            this.listData[0].gigId !== this.postInfo.gigId
          ) {
            this.$store.commit("setCanStartPlayingTheNextVideo", false);
            this.selectedThisVideo(this.listData[0]);
          }
        }
      },
      deep: true,
    },
  },
  methods: {
    // 选择播放这个视频
    selectedThisVideo(item) {
      this.$nextTick(() => {
        let multimediaPopupConfig = _.cloneDeep(
          this.$store.state.homePage.multimediaPopupConfig
        );
        if (item.attachments.length > 1) {
          for (let i = 0; i < item.attachments.length; i++) {
            if (
              this.matchFileSuffixType(item.attachments[i].path) === "video"
            ) {
              multimediaPopupConfig.videoPath = item.attachments[i].path;
              break;
            }
          }
        } else {
          multimediaPopupConfig.videoPath = item.attachments[0].path;
        }
        multimediaPopupConfig.videoCurrentTime = 1;
        multimediaPopupConfig.gigId = item.gigId;
        this.$store.dispatch(
          "commitMultimediaPopupConfig",
          multimediaPopupConfig
        );
      });
    },
    // 加载更多数据
    async loadData() {
      this.pageNum++;
      await this.getRecommendedList();
    },
    //时间格式化处理
    timeFormat(time) {
      let handleTime = "";
      try {
        let minute = Math.floor(time / 1000 / 60);
        let second = Math.floor((time / 1000) % 60);
        minute = minute < 10 ? "0" + minute : minute;
        second = second < 10 ? "0" + second : second;
        handleTime = `${minute}:${second}`;
      } catch (e) {
        handleTime = time;
      }
      return handleTime;
    },
    // 获取推荐视频列表
    getRecommendedList() {
      return new Promise(async (resolve, reject) => {
        let params = {
          documentType: 1,
          page: this.pageNum,
          pageSize: this.pageSize,
        };
        this.loadDisabled = true;
        let result = await getRecommendedList(this.postInfo.gigId, params);
        this.loadDisabled = false;
        if (result.code === 200) {
          // 判断一下是否有没有视频文件的数据，过滤掉
          let listData = result.data.data.filter((e) => {
            return e.attachments.length !== 0;
          });
          if (listData.length !== 0) {
            this.listData = this.listData.concat(listData);
          } else {
            // 如果没有数据，则将页面重置回去
            this.pageNum--;
          }
        } else {
          this.$message({
            type: "error",
            message: result.message,
          });
        }
        this.$nextTick(() => {
          resolve("success");
        });
      });
    },
  },
};
</script>
<style lang="stylus" scoped>
.list-enter-active, .list-leave-active {
  transition: opacity .3s;
}
.list-enter, .list-leave-to {
  opacity: 0;
}
.listAreaContent
  width 100%;
  height 100%;
  overflow-x hidden;
  overflow-y auto;
  position relative;
  scrollbar-width: none;
  &::-webkit-scrollbar
    width 0;
  .loadingContainer
    top 0;
    left 0;
    z-index 99;
    width 100%;
    height 100%;
    position absolute;
  .placeHolderContent
    width 100%;
    height 100%;
  .listContent
    height auto;
    width 100%;
    .listItem
      height 95px;
      width 100%;
      margin-top 10px;
      display flex;
      align-items center;
      justify-content flex-start;
      transition background .5s;
      &:hover
        cursor pointer;
        background rgba(242,252,248,0.7);
        border-radius 8px;
      .videoBlock
        width 165px;
        height 95px;
        flex-shrink 0;
        background rgba(0,0,0,0.9);
        position relative;
        .timeTextBlock
          position absolute;
          border-radius 2px;
          padding 1px 5px;
          background rgba(0,0,0,0.6);
          color #FFFFFF;
          font-size 10px;
          z-index 2;
          bottom 6px;
          right 6px;
          display flex;
          align-items center;
          justify-content center;
      .postInfoBlock
        flex 1;
        height 95px;
        min-width 0;
        box-sizing border-box;
        padding-left 14px;
        display flex;
        flex-flow column;
        align-items center;
        justify-content flex-start;
        .postTitle
          width 100%;
          height 48px;
          line-height 24px;
          font-size 14px;
          color #333333;
          overflow hidden;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
        .postAuthor
          width 100%;
          height 24px;
          line-height 24px;
          color #8F8F8F;
          font-size 12px;
          overflow: hidden;
          text-overflow:ellipsis;
          white-space: nowrap;
        .viewsAndDays
          width 100%;
          height 20px;
          display flex;
          align-items center;
          justify-content space-between;
          .viewsBox
            height 20px;
            width 60px
            flex-shrink 0
            display flex;
            align-items center;
            justify-content flex-start;
            .viewsIcon
              height 20px;
              width 20px;
              flex-shrink 0;
              .iconStyle
                color #8F8F8F;
                font-size 20px;
            .viewsText
              flex 1;
              height 20px;
              min-width 0;
              font-size 12px;
              color #8F8F8F;
              display flex;
              align-items center;
              padding-left 2px;
              box-sizing border-box;
              justify-content flex-start;
              overflow hidden;
              text-overflow:ellipsis;
              white-space: nowrap;
          .daysText
            flex 1;
            height 20px;
            min-width 0;
            font-size 12px;
            color #8F8F8F;
            overflow hidden
            display flex;
            align-items center;
            justify-content flex-end;
</style>
