var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "secondPieceContent" }, [
    _c(
      "div",
      { staticClass: "tabbarHeader" },
      [
        _c("Tabbar", {
          attrs: { tabbars: _vm.tabbars },
          on: { tabActive: _vm.tabActive }
        })
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "tabbarContent" },
      [
        _c(
          "el-carousel",
          {
            ref: "tabbarContent",
            attrs: {
              height: "100%",
              "initial-index": _vm.tabIndex,
              "indicator-position": "none",
              arrow: "never",
              autoplay: false,
              loop: false
            }
          },
          _vm._l(_vm.tabbars, function(item) {
            return _c(
              "el-carousel-item",
              { key: item.className },
              [
                _c(item.componentName, {
                  tag: "div",
                  attrs: { postInfo: _vm.postInfo },
                  on: {
                    replyMessageReceived: _vm.replyMessageReceived,
                    refreshCommentCount: _vm.refreshCommentCount
                  }
                })
              ],
              1
            )
          }),
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }