<template>
  <transition name="fade">
    <div class="popupPage" v-if="openPopup" ref="popupPage">
      <div class="displayArea">
        <span
          class="iconfont_Me icon-return returnButton"
          @click.stop="closePopup"
          v-if="!isFullscreen"
        ></span>
        <transition name="fade">
          <PicturePreview
            v-if="isShowPicture"
            :config="pictureConfig"
          ></PicturePreview>
        </transition>
        <transition name="fade">
          <LargeVideo
            v-if="isShowVideo"
            ref="largeVideoItem"
            :postTitle="postTitle"
            :config="videoConfig"
            @toggleFullScreen="toggleFullScreen"
            :isFullscreen="isFullscreen"
            @canStartPlayingTheNextVideo="canStartPlayingTheNextVideo"
            @canStartPlayingThePreviousVideo="canStartPlayingThePreviousVideo"
          ></LargeVideo>
        </transition>
      </div>
      <div
        class="sidebarContentOuter"
        v-if="$store.state.homePage.multimediaPopupConfig.showSidebar"
        @mouseenter="sidebarMouseenter"
        @mouseleave="sidebarMouseleave"
      >
        <transition name="operationButtonsFade">
          <div
            class="operationButtons"
            @click="changeSidebarStatus"
            v-if="showOperationButtons"
          >
            <span
              class="iconfont_Me iconStyle"
              :class="sidebarStatus ? 'icon-right' : 'icon-left'"
            ></span>
          </div>
        </transition>
        <Sidebar
          :class="sidebarStatus ? 'expandedState' : 'contractedState'"
          @changeVideoStatus="changeVideoStatus"
          @getIcanDo="getIcanDo"
        ></Sidebar>
      </div>
    </div>
  </transition>
</template>
<script>
// 引入多种功能的图片预览器
import PicturePreview from "@/newVersion/components/picturePreview/multifunction";
// 引入视频播放组件
import LargeVideo from "@/newVersion/components/videoPlayer/largeVersion";
// 引入侧边栏组件
import Sidebar from "./sidebar";
export default {
  name: "multimediaPopup",
  components: {
    PicturePreview,
    LargeVideo,
    Sidebar,
  },
  data() {
    return {
      // 视频配置
      videoConfig: {
        path: "",
        // 视频播放进度（秒）
        currentTime: 0,
        // 是否显示浮窗控制按钮
        floatingWindowPlay: true,
        // 是否展示放大按钮
        zoomControl: true,
        // 是否展示下载按钮
        downloadControl: true,
        // 音量控制按钮是否展示
        volumeControl: true,
      },
      // 图片配置
      pictureConfig: {
        images: [],
        zIndex: 0,
        showShare: true,
      },
      // 帖子标题
      postTitle: "",
      // 是否处于全屏状态
      isFullscreen: false,
      // 侧边栏展开状态
      sidebarStatus: true,
      // 鼠标移入侧边栏
      mouseenterFlag: false,
      // 鼠标移入定时器
      mouseenterTimer: null,
    };
  },
  computed: {
    // 打开当前弹窗
    openPopup() {
      return (
        this.multimediaPopupConfig.videoPath != "" ||
        this.multimediaPopupConfig.pictureCollection.length != 0
      );
    },
    // 弹窗参数
    multimediaPopupConfig() {
      return this.$store.state.homePage.multimediaPopupConfig;
    },
    // 展示视频窗口
    isShowVideo() {
      return this.multimediaPopupConfig.videoPath != "";
    },
    // 展示图片窗口
    isShowPicture() {
      return this.multimediaPopupConfig.pictureCollection.length != 0;
    },
    // 展示侧边栏状态操作按钮
    showOperationButtons() {
      return this.isFullscreen && this.mouseenterFlag;
    },
  },
  watch: {
    // 监听预览弹窗的帖子ID变化
    "multimediaPopupConfig.gigId": {
      handler(val, old) {
        // 当帖子ID变化时，获取帖子ID传入了一个空字符串，触发初始化
        if ((val && val !== old) || (val === "" && (old === "" || !old))) {
          this.judgmentWindowType();
        }
      },
      deep: true,
      immediate: true,
    },
  },
  beforeDestroy() {
    if (this.mouseenterTimer) {
      clearTimeout(this.mouseenterTimer);
    }
    // 弹窗关闭前，强制退出全屏模式
    if (this.fullscreenElement()) {
      const exitFun = document.exitFullscreen || document.webkitExitFullScreen;
      this.isFullscreen = false;
      //退出全屏状态要使用document
      exitFun.call(document);
    }
    document.removeEventListener("keyup", (e) => {
      if (e.keyCode == 27 && !this.isFullscreen) {
        this.closePopup();
      }
    });
  },
  mounted() {
    if (this.multimediaPopupConfig.gigId) {
      this.judgmentWindowType();
    }
    window.onresize = () => {
      if (!this.fullscreenElement()) {
        this.isFullscreen = false;
        // 打开侧边栏
        this.sidebarStatus = true;
      }
    };
    document.addEventListener("keyup", (e) => {
      if (e.keyCode == 27 && !this.isFullscreen) {
        this.closePopup();
      }
    });
  },
  methods: {
    // 接收帖子标题
    getIcanDo(iCanDo) {
      this.postTitle = iCanDo;
    },
    // 改变视频的播放状态
    changeVideoStatus() {
      if (this.isShowVideo) {
        this.$refs.largeVideoItem.isNowPlaying = false;
      }
    },
    // 根据不同数据，判断是视频还是图片弹窗
    judgmentWindowType() {
      if (this.isShowVideo) {
        // 将播放地址传入组件内
        this.$set(
          this.videoConfig,
          "path",
          this.multimediaPopupConfig.videoPath
        );
        // 将播放进度传入组件内
        this.$set(
          this.videoConfig,
          "currentTime",
          this.multimediaPopupConfig.videoCurrentTime
        );
        let videoRecordingArr = JSON.parse(
          JSON.stringify(this.$store.state.globalShare.videoRecording)
        );
        videoRecordingArr.push(_.cloneDeep(this.multimediaPopupConfig));
        this.$store.commit("setVideoRecording", videoRecordingArr);
      } else if (this.isShowPicture) {
        this.$set(
          this.pictureConfig,
          "images",
          this.multimediaPopupConfig.pictureCollection
        );
        this.$set(
          this.pictureConfig,
          "zIndex",
          this.multimediaPopupConfig.pictureIndex
        );
        this.$set(
          this.pictureConfig,
          "showShare",
          !this.multimediaPopupConfig.gigId == ""
        );
      }
    },
    // 收到通知，可以开始播放上一个视频了
    canStartPlayingThePreviousVideo() {
      if (this.$store.state.globalShare.videoRecording.length > 1) {
        let nowIndex = 0;
        this.$store.state.globalShare.videoRecording.forEach(
          (element, index) => {
            if (element.gigId === this.multimediaPopupConfig.gigId) {
              nowIndex = index;
            }
          }
        );
        let previousVideo = this.$store.state.globalShare.videoRecording[
          nowIndex - 1
        ];
        let multimediaPopupConfig = JSON.parse(
          JSON.stringify(this.$store.state.homePage.multimediaPopupConfig)
        );
        multimediaPopupConfig.videoPath = previousVideo.videoPath;
        multimediaPopupConfig.videoCurrentTime = 1;
        multimediaPopupConfig.gigId = previousVideo.gigId;
        this.$store.dispatch(
          "commitMultimediaPopupConfig",
          multimediaPopupConfig
        );
      } else {
        this.$message({
          type: "error",
          message: "这已经是第一个视频",
        });
      }
    },
    // 收到通知，可以开始播放下一个视频了
    canStartPlayingTheNextVideo() {
      this.$store.commit("setCanStartPlayingTheNextVideo", true);
    },
    // 关闭这个弹窗
    closePopup() {
      this.$nextTick(() => {
        this.$store.commit("setVideoRecording", []);
        this.$store.dispatch("commitClosemultimediaPopup");
      });
    },
    // 全屏状态下鼠标进入侧边栏
    sidebarMouseenter() {
      if (!this.isFullscreen) return;
      if (this.mouseenterTimer) {
        clearTimeout(this.mouseenterTimer);
      }
      this.mouseenterFlag = true;
    },
    // 全屏状态下鼠标移出侧边栏
    sidebarMouseleave() {
      if (!this.isFullscreen) return;
      this.mouseenterTimer = setTimeout(() => {
        this.mouseenterFlag = false;
      }, 2000);
    },
    // 改变侧边栏的展示状态
    changeSidebarStatus() {
      this.sidebarStatus = !this.sidebarStatus;
    },
    // 判断是否是全屏状态
    fullscreenElement() {
      let isFullscreen =
        document.fullscreenElement ||
        document.mozFullScreenElement ||
        document.webkitFullscreenElement ||
        document.fullScreen ||
        document.mozFullScreen ||
        document.webkitIsFullScreen;
      return isFullscreen;
    },
    //大屏切换
    toggleFullScreen() {
      const popupPage = this.$refs.popupPage;
      //如果当前是全屏状态，就退出全屏，否则进入全屏状态
      if (!this.fullscreenElement()) {
        const inFun =
          popupPage.requestFullscreen || popupPage.webkitRequestFullScreen;
        this.isFullscreen = true;
        // 将侧边栏收起
        this.sidebarStatus = false;
        //让当前播放器进入全屏状态
        inFun.call(popupPage);
      } else {
        const exitFun =
          document.exitFullscreen || document.webkitExitFullScreen;
        this.isFullscreen = false;
        //退出全屏状态要使用document
        exitFun.call(document);
      }
    },
  },
};
</script>
<style lang="stylus" scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .3s, transform .3s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
  transform scale(0.8);
}
.operationButtonsFade-enter-active, .operationButtonsFade-leave-active {
  transition: opacity .3s, transform .3s;
}
.operationButtonsFade-enter, .operationButtonsFade-leave-to {
  opacity: 0;
  transform translate3d(25px,0,0);
}
.popupPage
  top 0;
  height 0;
  width 100vw;
  height 100vh;
  z-index 2002;
  position fixed;
  display flex;
  flex-flow row;
  align-items center;
  justify-content flex-start;
  .displayArea
    height 100vh;
    flex 1;
    min-width 0;
    z-index 1
    box-sizing border-box;
    position relative;
    background rgba(0,0,0,0.9);
    .returnButton
      color #fff;
      font-size 30px;
      cursor pointer;
      display inline-block;
      position absolute;
      top 33px;
      left 34px;
      z-index 4;
  .sidebarContentOuter
    height 100vh;
    flex-shrink 0;
    background transparent;
    position relative;
    z-index 2
    transition width 0.3s, opacity 0.3s;
    display flex;
    align-items center;
    justify-content flex-end;
  .expandedState
    width 450px;
    opacity 1;
  .contractedState
    width 0px
    opacity 0;
  .operationButtons
    width 25px;
    z-index 2;
    height 140px;
    position absolute;
    left -25px;
    cursor pointer;
    border-radius 25px 0 0 25px;
    background rgba(255,255,255,0.5);
    display flex;
    align-items center;
    justify-content center;
    .iconStyle
      color #333333;
      font-size 24px;
</style>
