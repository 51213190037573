<template>
  <div class="displayAreaContent">
    <div
      class="displayArea"
      ref="imageItem"
      :style="{ overflowY: `${imageHeight == 'auto' ? 'auto' : 'hidden'}` }"
    >
      <transition name="fade">
        {{ formartImageUrl(showImage) }}
        <el-image
          v-if="showImage != ''"
          v-show="imageReady"
          :src="formartImageUrl(showImage)"
          :style="{
            transform: `scale(${scaleData}) rotate(${rotateData}deg)`,
            maxWidth: `1080px`,
            width: `100%`,
            height: `${imageHeight}`,
            transformOrigin: ``,
          }"
          @load="imageLoad"
          @mousemove.native="getMouseCoordinates($event)"
          fit="contain"
        >
        </el-image>
      </transition>
    </div>
    <div class="displayAreaFooter">
      <div class="footerInner" v-if="imageReady">
        <div
          class="unifiedButton"
          @click="rotateThePictureLeft"
          v-if="imageHeight != 'auto'"
        >
          <span
            class="el-icon-refresh-left"
            style="color:#fff;font-size:24px"
          ></span>
        </div>
        <div
          class="unifiedButton"
          style="margin:0 14px"
          @click="rotateThePicture"
          v-if="imageHeight != 'auto'"
        >
          <!-- <span class="iconfont_Me icon-replay icon-style"></span> -->
          <span
            class="el-icon-refresh-right"
            style="color:#fff;font-size:24px"
          ></span>
        </div>
        <div class="unifiedButton" @click="showThisPicture">
          <span class="iconfont_Me icon-Fullscreen icon-style"></span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    params: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  watch: {
    // 监听展示下标是否切换
    "params.zIndex": {
      handler(val, oldVal) {
        if (val != oldVal) {
          // 重置一下data数据
          Object.assign(this.$data, this.$options.data.call(this));
          this.$nextTick(() => {
            this.showImage = this.params.images[this.params.zIndex]
              ? this.params.images[this.params.zIndex]
              : "";
          });
        }
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      imageHeight: "100%",
      scaleData: 1.0,
      rotateData: 0,
      originX: "center",
      originY: "center",
      // 当前显示的图片地址
      showImage: "",
      // 加载完成，可以显示底部控制按钮
      imageReady: false,
    };
  },
  methods: {
    // 滚轮放大缩小图片前获取鼠标所在的坐标
    getMouseCoordinates(e) {
      // 超长图片不需要放大缩小功能
      if (this.imageHeight == "auto") {
        return;
      }
    },
    // 旋转图片
    rotateThePicture() {
      // 缩放先重置
      this.scaleData = 1.0;
      this.originX = "center";
      this.originY = "center";
      this.rotateData = this.rotateData + 90;
    },
    rotateThePictureLeft() {
      // 缩放先重置
      this.scaleData = 1.0;
      this.originX = "center";
      this.originY = "center";
      this.rotateData = this.rotateData - 90;
    },
    // 展示图片
    showThisPicture() {
      window.open(this.showImage, "_blank");
    },
    // 图片加载成功
    imageLoad() {
      this.getImgInfo().then((img) => {
        let pictureRatio = img.width / img.height;
        if (pictureRatio < 0.2) {
          this.imageHeight = "auto";
          this.$refs.imageItem.removeEventListener(
            "mousewheel",
            this.scrollHandler
          );
        } else {
          this.imageHeight = "100%";
          this.$refs.imageItem.addEventListener(
            "mousewheel",
            this.scrollHandler,
            false
          );
        }
        this.imageReady = true;
      });
    },
    scrollHandler(event) {
      const detail = event.wheelDelta || event.detail;
      if (detail < 0) {
        this.scaleData = this.scaleData + 0.2;
      } else {
        if (this.scaleData > 1.0) {
          this.scaleData = this.scaleData - 0.2;
        }
      }
    },
    // 获取当前图片的属性
    getImgInfo() {
      return new Promise((resolve, reject) => {
        let img = new Image();
        img.src = this.formartImageUrl(this.showImage);
        img.onload = () => {
          resolve(img);
        };
      });
    },
  },
};
</script>
<style lang="stylus" scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
.displayAreaContent
  width 100%;
  height 100%;
  position relative;
  display flex;
  align-items center;
  justify-content center;
  .displayArea
    z-index 1;
    width 100%;
    max-width 1080px;
    height 100%;
    scrollbar-width: none;
    overflow-x hidden;
    &::-webkit-scrollbar
      width 0px;
    &::-webkit-scrollbar-thumb
      border-radius: 2px;
      box-shadow: inset 0 0 5px rgba(100,100,100,0.2);
      background: rgba(100,100,100,0.2);
    &::-webkit-scrollbar-track
      box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
      border-radius: 0;
      background: rgba(0,0,0,0.5);
  .displayAreaFooter
    width 100%;
    z-index 2;
    height 50px;
    bottom 18px;
    position absolute;
    display flex;
    align-items center;
    justify-content center;
    .footerInner
      // width 148px;
      height 100%;
      display flex;
      flex-flow row;
      align-items center;
      justify-content space-around;
      .unifiedButton
        width 50px;
        height 50px;
        display flex;
        cursor pointer;
        border-radius 50%;
        align-items center;
        justify-content center;
        background rgba(0,0,0,0.5);
        .icon-style
          color #E6E6E6;
          font-size 24px;
</style>
