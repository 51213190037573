var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "listAreaContainer" },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.commentsLength !== 0,
              expression: "commentsLength !== 0"
            }
          ],
          staticClass: "containerBox"
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "infinite-scroll",
                  rawName: "v-infinite-scroll",
                  value: _vm.loadReplaysData,
                  expression: "loadReplaysData"
                }
              ],
              staticClass: "listAreaContent",
              attrs: {
                "infinite-scroll-disabled": "loadReplaysDisabled",
                "infinite-scroll-distance": "10",
                "infinite-scroll-immediate-check": "false"
              }
            },
            [
              _c("CommentList", {
                ref: "commentList",
                attrs: {
                  getCommentsParam: _vm.getCommentsParam,
                  showNewComment: true
                },
                on: {
                  replyMessageReceived: _vm.replyMessageReceived,
                  openSlidingWindow: _vm.openSlidingWindow,
                  getCommentCount: _vm.getCommentCount
                }
              })
            ],
            1
          ),
          _c(
            "transition",
            { attrs: { name: "fade" } },
            [
              _vm.showInSlidingWindow
                ? _c("MoreCommentSlidingWindow", {
                    attrs: { comment: _vm.commentForSlidingWindow },
                    on: {
                      closeSlidingWindow: _vm.closeSlidingWindow,
                      replyMessageReceived: _vm.replyMessageReceived
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c("PlaceholderMap", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.commentsLength === 0,
            expression: "commentsLength === 0"
          }
        ],
        attrs: { placeholderType: 0 }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }