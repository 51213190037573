var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("transition", { attrs: { name: "fade" } }, [
    _vm.openPopup
      ? _c("div", { ref: "popupPage", staticClass: "popupPage" }, [
          _c(
            "div",
            { staticClass: "displayArea" },
            [
              !_vm.isFullscreen
                ? _c("span", {
                    staticClass: "iconfont_Me icon-return returnButton",
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        return _vm.closePopup.apply(null, arguments)
                      }
                    }
                  })
                : _vm._e(),
              _c(
                "transition",
                { attrs: { name: "fade" } },
                [
                  _vm.isShowPicture
                    ? _c("PicturePreview", {
                        attrs: { config: _vm.pictureConfig }
                      })
                    : _vm._e()
                ],
                1
              ),
              _c(
                "transition",
                { attrs: { name: "fade" } },
                [
                  _vm.isShowVideo
                    ? _c("LargeVideo", {
                        ref: "largeVideoItem",
                        attrs: {
                          postTitle: _vm.postTitle,
                          config: _vm.videoConfig,
                          isFullscreen: _vm.isFullscreen
                        },
                        on: {
                          toggleFullScreen: _vm.toggleFullScreen,
                          canStartPlayingTheNextVideo:
                            _vm.canStartPlayingTheNextVideo,
                          canStartPlayingThePreviousVideo:
                            _vm.canStartPlayingThePreviousVideo
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _vm.$store.state.homePage.multimediaPopupConfig.showSidebar
            ? _c(
                "div",
                {
                  staticClass: "sidebarContentOuter",
                  on: {
                    mouseenter: _vm.sidebarMouseenter,
                    mouseleave: _vm.sidebarMouseleave
                  }
                },
                [
                  _c(
                    "transition",
                    { attrs: { name: "operationButtonsFade" } },
                    [
                      _vm.showOperationButtons
                        ? _c(
                            "div",
                            {
                              staticClass: "operationButtons",
                              on: { click: _vm.changeSidebarStatus }
                            },
                            [
                              _c("span", {
                                staticClass: "iconfont_Me iconStyle",
                                class: _vm.sidebarStatus
                                  ? "icon-right"
                                  : "icon-left"
                              })
                            ]
                          )
                        : _vm._e()
                    ]
                  ),
                  _c("Sidebar", {
                    class: _vm.sidebarStatus
                      ? "expandedState"
                      : "contractedState",
                    on: {
                      changeVideoStatus: _vm.changeVideoStatus,
                      getIcanDo: _vm.getIcanDo
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }