var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "displayAreaContent" }, [
    _c(
      "div",
      {
        ref: "imageItem",
        staticClass: "displayArea",
        style: { overflowY: `${_vm.imageHeight == "auto" ? "auto" : "hidden"}` }
      },
      [
        _c(
          "transition",
          { attrs: { name: "fade" } },
          [
            _vm._v(
              "\n      " +
                _vm._s(_vm.formartImageUrl(_vm.showImage)) +
                "\n      "
            ),
            _vm.showImage != ""
              ? _c("el-image", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.imageReady,
                      expression: "imageReady"
                    }
                  ],
                  style: {
                    transform: `scale(${_vm.scaleData}) rotate(${_vm.rotateData}deg)`,
                    maxWidth: `1080px`,
                    width: `100%`,
                    height: `${_vm.imageHeight}`,
                    transformOrigin: ``
                  },
                  attrs: {
                    src: _vm.formartImageUrl(_vm.showImage),
                    fit: "contain"
                  },
                  on: { load: _vm.imageLoad },
                  nativeOn: {
                    mousemove: function($event) {
                      return _vm.getMouseCoordinates($event)
                    }
                  }
                })
              : _vm._e()
          ],
          1
        )
      ],
      1
    ),
    _c("div", { staticClass: "displayAreaFooter" }, [
      _vm.imageReady
        ? _c("div", { staticClass: "footerInner" }, [
            _vm.imageHeight != "auto"
              ? _c(
                  "div",
                  {
                    staticClass: "unifiedButton",
                    on: { click: _vm.rotateThePictureLeft }
                  },
                  [
                    _c("span", {
                      staticClass: "el-icon-refresh-left",
                      staticStyle: { color: "#fff", "font-size": "24px" }
                    })
                  ]
                )
              : _vm._e(),
            _vm.imageHeight != "auto"
              ? _c(
                  "div",
                  {
                    staticClass: "unifiedButton",
                    staticStyle: { margin: "0 14px" },
                    on: { click: _vm.rotateThePicture }
                  },
                  [
                    _c("span", {
                      staticClass: "el-icon-refresh-right",
                      staticStyle: { color: "#fff", "font-size": "24px" }
                    })
                  ]
                )
              : _vm._e(),
            _c(
              "div",
              {
                staticClass: "unifiedButton",
                on: { click: _vm.showThisPicture }
              },
              [
                _c("span", {
                  staticClass: "iconfont_Me icon-Fullscreen icon-style"
                })
              ]
            )
          ])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }