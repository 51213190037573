var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      staticClass: "sidebarContent"
    },
    [
      _c(
        "div",
        { staticClass: "sidebarTopContent" },
        [
          _c("FirstPiece", {
            attrs: { postInfo: _vm.postInfo, blacklistInfo: _vm.blacklistInfo },
            on: { changelikeStatus: _vm.changelikeStatus }
          }),
          _c("SecondPiece", {
            attrs: { postInfo: _vm.postInfo },
            on: { replyMessageReceived: _vm.replyMessageReceived }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "sidebarFooterContent" },
        [
          _c("CommentInputBox", {
            attrs: { answerSomeone: _vm.answerSomeone, withPictures: true },
            on: {
              deleteAnswerSomeone: _vm.deleteAnswerSomeone,
              triggerSubmit: _vm.triggerSubmit,
              commentInputFocus: _vm.commentInputFocus
            }
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }